import { FormattedMessage } from 'react-intl';
import { fireTrackingEvent, TRACKING_CATEGORIES } from '../../../../../../tracking/gtm-helper';
import { RoleAccess, RoleContentData, RoleContentType, ServiceDescription } from './roleDescriptionContent';
import { AvailableRole } from './roleDescriptionSlice';

const generateTableHeader = (content: ServiceDescription[]) => (
    <thead>
        <tr>
            <th scope={'col'}>
                <FormattedMessage id={'roles.description.tableDeclaration'} />
            </th>
            {content.map((serviceDescription) => {
                const serviceNameMod = `${serviceDescription.service}.short`;
                return (
                    <th scope={'col'} key={serviceDescription.service} className={'text-center'}>
                        <FormattedMessage id={serviceNameMod} />
                    </th>
                );
            })}
        </tr>
    </thead>
);

const getAccessIcon = (access: RoleAccess) => {
    let icon = 'glyphicon rioglyph-';
    switch (access) {
        case 'roles.description.fullAccess':
            icon += 'ok text-color-success';
            break;
        case 'roles.description.restrictedAccess':
            icon += 'ok text-color-warning';
            break;
        case 'roles.description.noAccess':
            icon += 'remove text-color-danger';
            break;
        default:
            break;
    }
    return icon;
};

const getTableRow = (
    rowContent: RoleContentData,
    index: number,
    switchRoleFn: (role: AvailableRole) => void,
    role: AvailableRole
) => (
    <tr key={index}>
        <th scope={'row'} className={'text-left text-light'}>
            <a
                onClick={() => {
                    fireTrackingEvent(TRACKING_CATEGORIES.USERADMIN_INFO, 'click', role);
                    switchRoleFn(role);
                }}
            >
                {<FormattedMessage id={rowContent.titleId} />}
            </a>
        </th>
        {rowContent.serviceDescriptions!.map((serviceDescription) => {
            const { service } = serviceDescription;
            const icon = getAccessIcon(serviceDescription.description.access);
            return (
                <td key={`${rowContent.titleId}:${service}`}>
                    <span className={icon} />
                </td>
            );
        })}
    </tr>
);

const generateTableBody = (content: RoleContentType, switchRoleFn: (role: AvailableRole) => void) => {
    const availableRoles: AvailableRole[] = [];
    for (const role in content) {
        if (role !== 'Overview') {
            availableRoles.push(role as any);
        }
    }
    return (
        <tbody>
            {availableRoles.map((role, firstIndex) =>
                getTableRow(content[role] as any, firstIndex, switchRoleFn, role)
            )}
        </tbody>
    );
};

interface RoleDescriptionTableProps {
    fullContent: RoleContentType;
    switchRoleFn: (role: AvailableRole) => void;
}

export const RoleDescriptionTable = ({ fullContent, switchRoleFn }: RoleDescriptionTableProps) => (
    <div>
        <div className={'table-responsive margin-bottom-20'}>
            <table className={'table text-center margin-bottom-0'}>
                {generateTableHeader(fullContent.Administrator.serviceDescriptions!)}
                {generateTableBody(fullContent, switchRoleFn)}
            </table>
        </div>
        <div>
            <span className={'margin-right-20'}>
                <span className={'margin-right-5 glyphicon rioglyph-ok text-color-success'} />
                <FormattedMessage id={'roles.description.fullAccess'} />
            </span>
            <span className={'margin-right-20'}>
                <span className={'margin-right-5 glyphicon rioglyph-ok text-color-warning'} />
                <FormattedMessage id={'roles.description.restrictedAccess'} />
            </span>
            <span>
                <span className={'margin-right-5 glyphicon rioglyph-remove text-color-danger'} />
                <FormattedMessage id={'roles.description.noAccess'} />
            </span>
        </div>
    </div>
);
